import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Speakerphone = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.994 2.89L19 3v3.126a4.002 4.002 0 010 7.748v3.124l-.006.11a1 1 0 01-1.594.69c-1.736-1.302-3.7-2.15-5.9-2.544V20c0 3.293-4.708 3.293-5.47.243l-.094-.35-.116-.387a19.582 19.582 0 00-.067-.208l-.15-.446-.173-.485-.196-.523-.218-.561-.241-.6-.264-.637-.437-1.028-.404-.923c-1.541-.829-2.17-2.214-2.17-4.097 0-3.165 1.78-4.924 6.683-4.996L8.5 5c3.456 0 6.411-.933 8.9-2.8a1 1 0 011.594.69zM6.175 14.85l.235.552.282.687.257.646.233.605.106.287.195.544.17.504c.027.08.052.16.076.236l.133.443c.04.141.076.276.108.403.227.909 1.42.948 1.523.331L9.5 20v-4.975a18.412 18.412 0 00-1-.027l-.317-.002c-.739-.01-1.406-.06-2.008-.146zM17 4.853l-.17.099a15.944 15.944 0 01-5.33 1.82v6.455c1.923.303 3.701.91 5.33 1.82l.17.098V4.853zM8.963 6.995L8.5 7l-.285.002-.538.012c-3.281.117-4.134.983-4.175 2.838L3.5 10l.002.147c.041 1.854.894 2.72 4.175 2.837l.538.013.285.001.463.005c.18.004.359.01.537.02V6.975a20.16 20.16 0 01-.537.019zM19 8.268v3.464a2 2 0 000-3.464z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Speakerphone;
