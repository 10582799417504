import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Minus = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5 13.5h14a1.5 1.5 0 000-3H5a1.5 1.5 0 000 3z" fill="currentColor" fillRule="nonzero" />;
  </StyledSvg>
);
export default Minus;
