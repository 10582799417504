import React from 'react';
import { func, bool } from 'prop-types';
import { useTranslate } from '../../../translate/I18n';

import Box from '../../atoms/Box';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import Minus from '../../atoms/icons/Minus';
import Speakerphone from '../../atoms/icons/Speakerphone';
import SanitizedHTML from '../SanitizedHTML';

const BetaBanner = ({ closeBanner, isBannerDisplayed }) => {
  const { t } = useTranslate(['betaBanner']);
  const formURL = 'https://forms.gle/or6RQK5waXoLiPdp9';

  if (isBannerDisplayed === null) {
    return null;
  }

  return (
    <Box
      display={isBannerDisplayed ? 'block' : 'none'}
      position="fixed"
      bottom={{ _: 1, md: 10 }}
      left={{ _: 1, md: 5, lg: 8 }}
      right={{ _: 1, md: 5, lg: 8 }}
      mx="auto"
      zIndex="2147483648"
      maxWidth="1000px">
      <Box
        borderRadius={1}
        p={{ _: 1, md: 2 }}
        bg="#1B9B76"
        boxShadow="rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;">
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          <Box display="flex" alignItems="center" width="0" flex="1">
            <Box as="span" display="flex" p={1} borderRadius={1} bg="#15795D">
              <Speakerphone color="white" fontSize="20px" aria-hidden="true" />
            </Box>
            <Text ml={2} fontWeight="600" color="white">
              <Text mb={'4px'} fontSize="18px" fontWeight="800">
                {t('betaBanner:title')}
              </Text>
              <span><SanitizedHTML content={t('betaBanner:description')} /></span>
            </Text>
          </Box>
          <Box order={{ _: 2, md: 3 }} flexShrink="0" ml={{ _: 0, md: 1 }}>
            <Button styleType="transparent" display="flex" pr={1} onClick={() => closeBanner()}>
              <Box
                as="span"
                position="absolute"
                width="1px"
                height="1px"
                padding="0"
                margin="-1px"
                overflow="hidden"
                clip="rect(0, 0, 0, 0)"
                whiteSpace="nowrap"
                borderWidth="0">
                <span>{t('betaBanner:dismiss')}</span>
              </Box>
              <Minus color="white" fontSize="20px" aria-hidden="true" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BetaBanner.propTypes = {
  closeBanner: func,
  isBannerDisplayed: bool
};

export default BetaBanner;
