import React, { PureComponent } from 'react';
import { string, array, object, oneOfType, bool } from 'prop-types';
import { isNil, cond, T, equals, identity, is } from 'ramda';
import sanitizeHtml from 'sanitize-html-react';

const SanitizedHTML = ({ content }) => {

  const options = {
    allowedTags: false,
    allowedAttributes: false,
    allowedSchemes: ['data', 'http', 'https']
  };

  const sanitizedContent = sanitizeHtml(content, options);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
};

SanitizedHTML.propTypes = {
  content: string
};

SanitizedHTML.defaultProps = {
  content: ''
};

export default SanitizedHTML;
